@charset "utf-8";

@import "so-simple";

.basic-grey {
	margin-left:auto;
	margin-right:auto;
    max-width: 500px;
    padding: 25px 15px 25px 10px;
    color: #000;
    text-shadow: 1px 1px 1px #FFF;
}
.basic-grey h1 {
    font-size: 25px;
    padding: 0px 0px 10px 40px;
    display: block;
    border-bottom:1px solid #E4E4E4;
    margin: -10px -15px 30px -10px;;
    color: #000;
}

.basic-grey h1>span {
    display: block;
    font-size: 11px;
}
.basic-grey label {
    display: block;
    margin: 0px;
}
.basic-grey label>span {
    float: left;
    width: 20%;
    text-align: right;
    padding-right: 10px;
    margin-top: 4px;
    color: #000;
}

.basic-grey input[type="text"], .basic-grey input[type="email"], .basic-grey textarea, .basic-grey select {
	border: 1px solid #DADADA;
	color: #000;
	height: 30px;
	margin-bottom: 16px;
	margin-right: 6px;
	margin-top: 2px;
	outline: 0 none;
	padding: 3px 3px 3px 10px;
	width: 70%;
	line-height:15px;
	box-shadow: inset 0px 1px 4px #ECECEC;
	-moz-box-shadow: inset 0px 1px 4px #ECECEC;
	-webkit-box-shadow: inset 0px 1px 4px #ECECEC;
	font-family: $sans-serif-font-family;
	font-size: $base-font-size;

}
.basic-grey textarea{
	padding: 5px 3px 3px 5px;
}
.basic-grey select {
  background: #FFF url('/images/down-arrow.png') no-repeat right;
  background: #FFF url('/images/down-arrow.png') no-repeat right;
  appearance:none;
  -webkit-appearance:none;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
  width: 70%;
  height: 37px;
	line-height: 25px;
	padding-left: 10px
}
.basic-grey textarea{
    height:250px;
}
.basic-grey .button {
	background: #000;
	border: none;
	padding: 10px 25px 10px 25px;
	color: #FFF;
	box-shadow: 1px 1px 5px #B6B6B6;
	border-radius: 3px;
	text-shadow: 1px 1px 1px #000000;
	cursor: pointer;
  font-family: $sans-serif-font-family;
	font-size: $base-font-size;

}
.basic-grey .button:hover {
  background: #CF7A7A
}

.basic-grey .honey {
    display: none;
    visibility: hidden;
}
